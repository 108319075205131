import React from 'react'
import './about.css'
import ME from '../../assets/me-about.webp'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        <div className="about_content">
          <div className="about_cards">
            <article className='about_card'>
              <FaAward className='about_icon'/>
              <h5>Experience</h5>
              <small>5+ Years Working</small>
            </article>

            <article className='about_card'>
              <FiUsers className='about_icon'/>
              <h5>Clients</h5>
              <small>100+ Worldwide</small>
            </article>

            <article className='about_card'>
              <VscFolderLibrary className='about_icon'/>
              <h5>Project</h5>
              <small>20+ Completed</small>
            </article>
          </div>

          <p>
          I am a Full Stack Java Developer, graduated from Dokuz Eylül University with a degree in Computer Programming. I have also completed a Full Stack Java Developer bootcamp, which equipped me with comprehensive skills in Java development.
          <br /><br />
          Throughout my career, I have embraced AGILE methodologies for software development, allowing me to effectively collaborate with teams and deliver high-quality products in a timely manner.
          <br /><br />
          With a passion for continuous learning and professional growth, I am always eager to explore new technologies and stay up-to-date with the latest industry trends. I thrive in dynamic environments that foster creativity and innovation.
          <br /><br />
          Combining technical expertise with a strong work ethic, I am dedicated to delivering exceptional results and solving complex problems. I possess excellent analytical skills and have a keen eye for detail, enabling me to tackle challenges with a strategic and efficient approach.
          </p>
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About