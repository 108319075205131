import React from 'react'
import "./qualification.css"
import Data from './Data'
import Card from './Card'

const Qualification = () => {
  return (
    <section className='qualification container section' id="qualification">
      <h5>The Experiences I Have</h5>
      <h2>My Qualification</h2>

      <div className="resume_container">
        <div className="timeline">
        <h3 className='timeline_oneTitle'>Education</h3>
          {Data.map((val, id) => {
            if (val.category === "education"){
              return (
                  <Card key={id} icon={val.icon} title={val.title} sortTitle={val.sortTitle} year={val.year} desc={val.desc}/>
              )
            }
          })}
        </div>

        <div className="timeline">
        <h3 className='timeline_oneTitle'>Experience</h3>
          {Data.map((val, index) => {
            if (val.category === "experience"){
              return (
                <Card key={index} icon={val.icon} title={val.title} sortTitle={val.sortTitle} year={val.year} desc={val.desc}/>
              )
            }
          })}
        </div>
      </div>
    </section>
  )
}

export default Qualification