const Data = [
  {
    id:1,
    category: "education",
    icon: "icon-graduation",
    year:"2023",
    title: "Associate Degree",
    sortTitle: "Dokuz Eylül University",
    desc: "Computer Programming"
  },
  {
    id:2,
    category: "experience",
    icon: "icon-briefcase",
    year:"Oct 2021 - Present",
    title: "Seo Specialist",
    sortTitle: "Freelancer",
    desc: " "
  },
  {
    id:3,
    category: "experience",
    icon: "icon-briefcase",
    year:"Jan 2023 - Present",
    title: "Full Stack Java Developer and Wordpress Developer",
    sortTitle: "Freelancer",
    desc: " "
  },
  {
    id:4,
    category: "experience",
    icon: "icon-briefcase",
    year:"Oct 2021 - Jul 2022",
    title: "Seo Executive",
    sortTitle: "Hafsa Software",
    desc: "Neu Wulmstorf, Germany · Remote"
  },
  {
    id:5,
    category: "experience",
    icon: "icon-briefcase",
    year:"Aug 2022 - Oct 2022",
    title: "Mid-Level Seo Specialist",
    sortTitle: "Dijitanya Seo & Dijital Performans Ajansı",
    desc: "İstanbul, Türkiye · Remote"
  },
  {
    id:6,
    category: "experience",
    icon: "icon-briefcase",
    year:"Feb 2023 - Jun 2023",
    title: "Java Developer Intern",
    sortTitle: "Politeknik Elektronik",
    desc: "İzmir, Türkiye · On-site"
  },
];

export default Data