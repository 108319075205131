import React from 'react'
import {BsLinkedin, BsGithub, BsTwitter} from 'react-icons/bs'

const Social = () => {
  return (
    <div className="home_social">
      <a href="https://www.linkedin.com/in/bilgehan-comert/" rel="nofollow" target='_blank'><BsLinkedin/></a>
      <a href="https://github.com/bilgehancomert" rel="nofollow" target='_blank'><BsGithub/></a>
      <a href="https://twitter.com/ComertBilgehan" rel="nofollow" target='_blank'><BsTwitter/></a>
    </div>
  )
}

export default Social