import React from 'react'
import Header from './components/header/Header'
import Home from './components/home/Home'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Skills from './components/skills/Skills'
import Qualification from './components/qualification/Qualification'
import Portfolio from './components/portfolio/Portfolio'
import Services from './components/services/Services'
import Testimonials from './components/testimonials/Testimonials'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'

const App = () => {
  return (
    <>
      <Home/>
      {/*<Header/>*/}
      <Nav/>
      <About/>
      <Skills/>
      <Qualification/>
      <Portfolio/>
      {/*<Services/>*/}
      {/*<Testimonials/>*/}
      <Contact/>
      <Footer/>
    </>
  )
}

export default App