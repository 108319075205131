import React from 'react'
import {CgScrollV} from 'react-icons/cg'

const ScrollDown = () => {
  return (
    
    <div className="home_scroll">
      <a href="#contact" className="home_scroll-btn"> Scroll Down <CgScrollV className='scr_icon'/></a>
    </div>
  )
}

export default ScrollDown