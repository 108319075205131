import React from 'react'
import './footer.css'
import {BsLinkedin, BsGithub, BsTwitter} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer_logo'>Bilgehan Cömert</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#skills">Skills</a></li>
        <li><a href="#qualification">Qualification</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        {/*<li><a href="#testimonials">Testimonials</a></li>*/}
        {/*<li><a href="#services">Services</a></li>*/}
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer_socials">
      <a href="https://www.linkedin.com/in/bilgehan-comert/" rel="nofollow" target='_blank'><BsLinkedin/></a>
      <a href="https://github.com/bilgehancomert" rel="nofollow" target='_blank'><BsGithub/></a>
      <a href="https://twitter.com/ComertBilgehan" rel="nofollow" target='_blank'><BsTwitter/></a>
      </div>

      <div className="footer_copyright">
        <small>&copy; Bilgehan CÖMERT Tutorials. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer