import React, { useState } from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio2.webp'
import IMG2 from '../../assets/portfolio3.webp'
import underConstIMG from '../../assets/under_construction.webp'
import sanalkolejim from '../../assets/sanalkolejim.webp'
import herbalmarketim from'../../assets/herbalmarketim.webp'
import herbalyaninda from '../../assets/herbalyaninda.webp'

const generalProjects = [
  {
    id: 1,
    image: underConstIMG,
    title: 'Greenrent Project',
    github: 'https://github.com/bilgehancomert?tab=repositories',
    demo: 'https://greenrent.bilgehancomert.dev/'
  },
  {
    id: 2,
    image: IMG1,
    title: 'Visionrent Project',
    github: 'https://github.com/bilgehancomert/visionrentProject',
    demo: 'https://visionrent.bilgehancomert.dev/'
  },
  {
    id: 3,
    image: IMG2,
    title: 'React Admin Project',
    github: 'https://github.com/bilgehancomert/reactAdminDashboardProject',
    demo: 'https://reactadminproject.bilgehancomert.dev/'
  },
  {
    id: 4,
    image: underConstIMG,
    title: 'E Commerce Project',
    github: 'https://github.com/bilgehancomert?tab=repositories',
    demo: 'https://ecommerceproject.bilgehancomert.dev/'
  }
]

const wordpressProjects = [
  {
    id: 1,
    image: sanalkolejim,
    title: 'Sanal Kolejim',
    demo: 'https://sanalkolejim.com/'
  },
  {
    id: 2,
    image: herbalmarketim,
    title: 'Herbal Marketim',
    demo: 'https://herbalmarketim.com/'
  },
  {
    id: 3,
    image: herbalyaninda,
    title: 'Herbal Yanında',
    demo: 'https://herbalyaninda.com.tr//'
  }
]

const Portfolio = () => {

  const [currentCategory, setCurrentCategory] = useState('general');

  const handleCategoryChange = (category) => {
    setCurrentCategory(category);
  };

  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      {/* Kategori Seçim Butonları */}
      <div className="portfolio-buttons">
        <a
          className={`btn ${currentCategory === 'general' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('general')}
        >
          General Projects
        </a>
        <a
          className={`btn ${currentCategory === 'wordpress' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('wordpress')}
        >
          WordPress Projects
        </a>
      </div>

      <div className="container portfolio_container">
        {currentCategory === 'general' &&
          generalProjects.map(({ id, image, title, github, demo }) => (
            <article key={id} className='portfolio_item'>
              <div className='portfolio_item-image'>
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio_item-cta">
                <a href={github} className='btn' rel="nofollow" target='_blank'>
                  Github
                </a>
                <a
                  href={demo}
                  className='btn btn-primary'
                  rel="nofollow"
                  target='_blank'
                >
                  Live Demo
                </a>
              </div>
            </article>
          ))}

        {currentCategory === 'wordpress' &&
          wordpressProjects.map(({ id, image, title, demo }) => (
            <article key={id} className='portfolio_item'>
              <div className='portfolio_item-image'>
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio_item-cta">
                <a
                  href={demo}
                  className='btn btn-primary'
                  rel="nofollow"
                  target='_blank'
                >
                  Live
                </a>
              </div>
            </article>
          ))}
      </div>
    </section>
  )
}

export default Portfolio