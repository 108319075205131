import React from 'react'

const Data = () => {
  return (
    <div className="home_data">
      <h1 className='home_title'>Bilgehan Cömert</h1>
      <h3 className='home_subtitle'>Full Stack Java Developer and SEO Specialist</h3>
      <p className="home_description">I'm creative Full Stack Developer based in Izmir and I am very passionate and committed to my work.</p>

      <a href="#contact" className="btn btn-primary">Send Message</a>
    </div>
  )
}

export default Data